
import {machine as appReviewMachine} from "../machines/appReview"
import {machine as authFlowMachine} from "../machines/authFlow"
import {createActor,sendParent} from "xstate";

const machines = {
    authFlowMachine,
    appReviewMachine
}
const globalActors = {}

/**
 *
 * useStateMachine(name,options)
 *
 * in order to initialize machine context add this line to machine config: context:({input})=>input
 * and then provide context as input in options = { input:{...yourContext},... }
 *
 * This method is for global state machines only - its first invocation will set up and start machine.
 * Machine is never stopped (even if it is reaching its 'done' state).
 *
 * @param name
 * @param options
 * @returns {*|null}
 */
const store = useStore()
export const useStateMachine = (name,options={})=> {
    if(!machines[name+"Machine"]) return null
    //console.log('getting machine',name+"Machine",machines[name+"Machine"])
    const machine = machines[name+"Machine"]
    if(!store.machines[name]){
        // initialize machine
        store.machines[name] = reactive({
            id:machine.id,
            state:'',
            context:{},
            events:machine.events,
        })
        startMachine(store.machines[name],machine,options)
    }
    return store.machines[name]
}

function startMachine(actorRef,machine,options){
    const {input} = options
    console.log(`MACHINE starting (${machine.id})`)
    actorRef.actor = shallowReactive(createActor(machine.provide(options),{input}))
    actorRef.send = (arg)=> {
        if(actorRef.status==='active') actorRef.actor.send(arg)
    }
    actorRef.actor.subscribe((snap)=>{
        console.log(`MACHINE new state (${machine.id}):`,snap.value, 'CONTEXT:',store.mobileApp?JSON.stringify(snap.context):snap.context)
        actorRef.state = snap.value
        actorRef.context = snap.context
        actorRef.status = snap.status
    })
    actorRef.actor.start()
    actorRef.implementations = machine.implementations
}

export const machineDialog = (actor,options,onConfirm,onCancel)=>{
    showDialog(options)
        .then(()=>{
            if(_isFunction(onConfirm)) onConfirm()
            else actor.send({type:onConfirm})
        })
        .catch(()=>{
            if(_isFunction(onCancel)) onCancel()
            else actor.send({type:onCancel})
    })
}