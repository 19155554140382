

export const usePromo = (promo= null)=>{
    const store = useStore()
    const {member} = useMember()
    const {calculate} = useJsExpression()

    //console.log('promo',promo)
    const p = promo ? _find(store.promos,{id:promo}) : store.program
    if(!p?.id){
        return {}
        showDialog({
            message:`Promo ${promo} not ready`
        })

    }
    return {
        promo: p,
        awards: store.awards[p.id],
        state: computed(()=>member?.profile?.promos?.[p.id]),
        accounts: computed(()=>{
            const a = {}
            _each(p.schema.accounts,acc=>{
                a[acc.name] = calculate(acc.formula||acc.name, member?.profile?.promos?.[p.id])
            })
            return a
        }),
        isConsented: computed(()=>{
            return _reduce(member?.profile?.promos?.[p.id].consents,(acc,c)=>{
                return acc && (c.required && !c.value)
            },true)
        })
    }
}